<template>
  <div>
    <b-container>
      <Owner />
      <b-row align-v="center">
        <b-col>
          <b-row>
            <NavbarInfo />
          </b-row>
        </b-col>
      </b-row>
      <b-card-group deck id="Form">
        <b-card>
          <b-card-text class="mt-3 ml-4 tableMedicineItem" id="intro">
            <h5>{{ $t(`formMedical.titulo`) }}</h5>
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <!-- <b>¿Ya has realizado un tratamiento de reproducción asistida? </b> -->
                <b>{{ $t(`formMedical.tratamientosprevios`) }}</b>
                <br />{{ data.tratamientosprevios }} </b-col
              ><b-col></b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <!-- <b>¿Hace cuánto estás buscando tener un bebé?</b> -->
                <b>{{ $t(`formMedical.timpoIntentoBebe`) }}</b>
                <br />{{ data.tiempointentandobebe }} </b-col
              ><b-col></b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <!-- <b>¿En cuánto tiempo te gustaría tener a tu bebé?</b>  -->
                <b>{{ $t(`formMedical.tiempodispuestaesperar`) }}</b>
                <br />{{ data.tiempodispuestaesperar }} </b-col
              ><b-col></b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.altura`) }}</b>
                <br />{{ data.ldb_altura }}
              </b-col>
              <b-col></b-col>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.peso`) }}</b> <br />{{ data.ldb_peso }}
              </b-col>
              <b-col></b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.primerPeriodo`) }}</b>
                <br />{{ data.primerperiodo }} </b-col
              ><b-col></b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.diasPeriodo`) }}</b>
                <br />
                {{ data.duracionperiodo }} </b-col
              ><b-col></b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.dolorPeriodo`) }}</b>
                <br />
                {{ data.dolorperiodo }} </b-col
              ><b-col></b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1 cont-div-fm-check">
                <b>{{ $t(`formMedical.padecimientos`) }}</b>
                <!--<p>{{data.padecimientos}}</p>-->
                <b-row>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('diabetes', data.padecimientos) }"></span
                     >{{ $t(`formMedical.diabetes`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('cardiacos', data.padecimientos) }"></span
                     >{{ $t(`formMedical.cardiacos`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('hipertension', data.padecimientos) }"></span
                     >{{ $t(`formMedical.hipertension`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('cancer', data.padecimientos) }"></span
                     >{{ $t(`formMedical.cancer`) }}</b-col
                  >
                </b-row>
                <b-row>
                  <b-col><span v-bind:class="{ active: validateCheck('VIH', data.padecimientos) }"></span
                    >{{ $t(`formMedical.vih`) }}</b-col>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('hepatitis', data.padecimientos) }"></span
                     >{{ $t(`formMedical.hepatitis`) }}</b-col
                  >
                  <b-col><span v-bind:class="{ active: validateCheck('otros', data.padecimientos) }"></span
                    >{{ $t(`formMedical.hepatitis`) }}</b-col>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('ninguno', data.padecimientos) }"></span
                      >{{ $t(`formMedical.ninguno`) }}</b-col
                  >
                </b-row>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1  cont-div-fm-check">
                <b>{{ $t(`formMedical.padecimientosFamiliares`) }}</b>
                <!--<p>{{data.padecimientos_familia}}</p>-->
                <b-row>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('diabetes', data.padecimientos_familia) }"></span
                     >{{ $t(`formMedical.diabetes`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('cardiacos', data.padecimientos_familia) }"></span
                     >{{ $t(`formMedical.cardiacos`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('hipertension', data.padecimientos_familia) }"></span
                     >{{ $t(`formMedical.hipertension`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('cancer', data.padecimientos_familia) }"></span
                     >{{ $t(`formMedical.cancer`) }}</b-col
                  >
                </b-row>
                <b-row>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('VIH', data.padecimientos_familia) }"></span
                     >{{ $t(`formMedical.vih`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('hepatitis', data.padecimientos_familia) }"></span
                     >{{ $t(`formMedical.hepatitis`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('otros', data.padecimientos_familia) }"></span
                    >{{ $t(`formMedical.otros`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('ninguno', data.padecimientos_familia) }"></span
                      >{{ $t(`formMedical.ninguno`) }}</b-col
                  >
                </b-row>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1 cont-div-fm-check">
                <b>{{ $t(`formMedical.padecimientosInfertilidad`) }}</b>
                <!--<p>{{data.padecimientos_fertilidad}}</p>-->
                <b-row>
                  <b-col
                    ><span
                      v-bind:class="{ active: validateCheck('endometriosis', data.padecimientos_fertilidad) }"
                    ></span
                    >{{ $t(`formMedical.endometriosis`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('miomatosis', data.padecimientos_fertilidad) }"></span
                    >{{ $t(`formMedical.miomatosis`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('quistes', data.padecimientos_fertilidad) }"></span
                    >{{ $t(`formMedical.quistes`) }}</b-col
                  >
                  <b-col
                    ><span
                      v-bind:class="{ active: validateCheck('agnesia_uterina', data.padecimientos_fertilidad) }"
                    ></span
                    >{{ $t(`formMedical.agnesia_uterina`) }}</b-col
                  >
                </b-row>
                <b-row>
                  <b-col
                    ><span
                      v-bind:class="{ active: validateCheck('ovario_poliquistico', data.padecimientos_fertilidad) }"
                    ></span
                    >{{ $t(`formMedical.ovario_poliquistico`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('otros', data.padecimientos_fertilidad) }"></span
                    >{{ $t(`formMedical.otros`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('ninguno', data.padecimientos_fertilidad) }"></span
                      >{{ $t(`formMedical.ninguno`) }}</b-col
                  >
                </b-row>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1 cont-div-fm-check">
                <b>{{ $t(`formMedical.estudiosInfertilidad`) }}</b>
                <!--<p>{{data.estudios_fertilidad}}</p>-->
                <b-row>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('ultrasonido', data.estudios_fertilidad) }"></span
                    >{{ $t(`formMedical.ultrasonido`) }}</b-col
                  >
                  <b-col
                    ><span
                      v-bind:class="{ active: validateCheck('transferencia_embionaria', data.estudios_fertilidad) }"
                    ></span
                    >{{ $t(`formMedical.transferencia_embionaria`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('hormonales', data.estudios_fertilidad) }"></span
                    >{{ $t(`formMedical.hormonales`) }}</b-col
                  >
                </b-row>
                <b-row>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('seminograma', data.estudios_fertilidad) }"></span
                    >{{ $t(`formMedical.seminograma`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('', data.estudios_fertilidad) }"></span
                      >{{ $t(`formMedical.otros`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('ninguno', data.estudios_fertilidad) }"></span
                      >{{ $t(`formMedical.ninguno`) }}</b-col
                  >
                </b-row>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.embarazada`) }}</b>
                <p>{{ data.embarazoprevio }}</p> </b-col
              ><b-col></b-col>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.partos`) }}</b>
                <p>{{ data.partosprevios }}</p> </b-col
              ><b-col></b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1 cont-div-fm-check">
                <b>{{ $t(`formMedical.procedimientos`) }}</b>
                <!--<br>{{data.procedimientos_previos}}-->
                <b-row>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('dialisis', data.procedimientos_previos) }"></span
                    >{{ $t(`formMedical.dialisis`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('transplantes', data.procedimientos_previos) }"></span
                    >{{ $t(`formMedical.transplantes`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('legrado', data.procedimientos_previos) }"></span
                    >{{ $t(`formMedical.legrado`) }}</b-col
                  >
                </b-row>
                <b-row>
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('cesarea', data.procedimientos_previos) }"></span
                    >{{ $t(`formMedical.cesarea`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('', data.procedimientos_previos) }"></span
                      >{{ $t(`formMedical.otros`) }}</b-col
                  >
                  <b-col
                    ><span v-bind:class="{ active: validateCheck('ninguno', data.procedimientos_previos) }"></span
                      >{{ $t(`formMedical.ninguno`) }}</b-col
                  >
                </b-row>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.alergias`) }}</b>
                <br />{{ data.ldb_alergias }} </b-col
              ><b-col></b-col>
              <b-col class="cont-div-fm ml-1">
                <b>{{ $t(`formMedical.medicamentos`) }}</b>
                <br />{{ data.ldb_medicamentosactuales }} </b-col
              ><b-col></b-col>
            </b-row>
            <br />
            <br /><br />
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-container>
  </div>
</template>

<script>
//import { ApiRequest } from '../../helpers/ApiRequest';
import Owner from '@/components/Owner';
import NavbarInfo from '@/components/NavbarInfo';
import { AlertForm, ICON } from '../helpers/Alert';
import { API_CENTRAL } from '../Constants/Api';

export default {
  name: 'Form',
  components: {
    NavbarInfo,
    Owner,
  },
  data() {
    return {
      country: '',
      items: [],
      configRol: {},
      data: {},
      mail: '',
    };
  },
  created() {},
  watch: {
    updateConfigRol() {
      this.configRol = this.updateConfigRol;
      if (this.configRol.medicina?.ventana === false) {
        this.$router.push({ name: 'Welcome' });
        AlertForm(
          `${this.$i18n.t('permissions.unauthorized')} ${this.$i18n.t('menu.Medicine')}`,
          this.$i18n.t('permissions.validateAdmin'),
          ICON.WARNING,
          'center',
          null
        );    

      }
    },
    '$i18n.locale': {
    handler: 'reloadData', // llama a la función reloadData cuando cambia el idioma
    immediate: true, // para que se ejecute al inicio también
  },
  },
  async mounted() {
    this.country = sessionStorage.getItem('country');
    this.mail = sessionStorage.getItem('email');
    // try {
    //   await this.DatosMedicos();
    // } catch (err) {
    //   console.log('');
    // }
  },
  methods: {
    async reloadData() {
      try {
        await this.DatosMedicos();
      } catch (err) {
        console.log('');
      }
    },
    validateCheck(element, fullString) {
      var validacion = false;
      if (fullString != null) {
        var stringArray = fullString.split(',');
        stringArray.forEach(function(item) {
          if (element == item) {
            validacion = true;
          }
        });
      }
      return validacion;
    },
    async DatosMedicos() {
      //Obtenemos el token
      var myHeadersT = new Headers();
      myHeadersT.append('Content-Type', 'application/x-www-form-urlencoded');
      var urlencodedT = new URLSearchParams();
      urlencodedT.append('key', '597633da-228f-10bd-cb29-b634ae8539aa');
      var requestOptionsT = {
        method: 'POST',
        headers: myHeadersT,
        body: urlencodedT,
        redirect: 'follow',
      };

      fetch(`${API_CENTRAL}validateUser`, requestOptionsT)
        .then((response) => response.text())
        .then((resultT) => {
          var myHeaders = new Headers();
          myHeaders.append('authorization', JSON.parse(resultT).token);
          myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
          var urlencoded = new URLSearchParams();
          urlencoded.append('email', sessionStorage.getItem('email'));
          urlencoded.append("idioma", this.$i18n.locale);

          //urlencoded.append("lang", "es");
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow',
          };
   

          fetch(`${API_CENTRAL}apipost/getDatosMedico`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              try {      
                this.data = JSON.parse(result).addenda[0];
                // Convertir respuestas a los campos 'ldb_alergias' y 'ldb_medicamentosactuales'
                this.convertirRespuestas();
              } catch {
                AlertForm(`Error Code: ${JSON.parse(result).valido}`, JSON.parse(result).mensaje, ICON.ERROR);
              }
            })
            .catch((error) => {
              if (error !== 'Data Failure') {
                AlertForm(`Error Code: ${error}`, error, ICON.ERROR);
              } else {
                console.log('sin error');
              }
            });
        })
        .catch((error) => console.log('error', error));
    },
    // Función para convertir las respuestas de los campos 'ldb_alergias' y 'ldb_medicamentosactuales'
    convertirRespuestas() {
      // console.log('idioma en onboarding: ',this.$i18n.locale)
      if (this.data.ldb_alergias === 'si') {
        this.data.ldb_alergias = this.$i18n.locale === 'es' ? 'si' : 'yes';
      } else if (this.data.ldb_alergias === 'no') {
        this.data.ldb_alergias = this.$i18n.locale === 'es' ? 'no' : 'no';
      } else {
        this.data.ldb_alergias = '';
      }

      if (this.data.ldb_medicamentosactuales === 'si') {
        this.data.ldb_medicamentosactuales = this.$i18n.locale === 'es' ? 'si' : 'yes';
      } else if (this.data.ldb_medicamentosactuales === 'no') {
        this.data.ldb_medicamentosactuales = this.$i18n.locale === 'es' ? 'no' : 'no';
      } 
      else {
        this.data.ldb_medicamentosactuales = '';
      }
    },
  },
};
</script>
<style scoped>
@import '../assets/css/global.css';
.cont-div-fm {
  border-bottom: 1px solid #555;
  font-size: 20px;
}
.cont-div-fm b {
  color: #003085;
  font-weight: 100;
  font-size: 17px;
}
.cont-div-fm-check {
  border: 0;
  font-size: 17px;
  line-height: 40px;
}
.cont-div-fm-check span {
  border: 1px solid #003085;
  width: 13px;
  height: 13px;
  display: block;
  float: left;
  line-height: 40px;
  margin-right: 12px;
  margin-top: 14px;
}
.cont-div-fm-check span.active::after {
  content: '';
  position: absolute;
  line-height: 10px;
  background: #003085;
  width: 8px;
  height: 8px;
  margin-top: 1px;
  margin-left: 1px;
  opacity: 0.8;
}
</style>