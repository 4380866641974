var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        [
          _c("Owner"),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [_c("b-col", [_c("b-row", [_c("NavbarInfo")], 1)], 1)],
            1
          ),
          _c(
            "b-card-group",
            { attrs: { deck: "", id: "Form" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-card-text",
                    {
                      staticClass: "mt-3 ml-4 tableMedicineItem",
                      attrs: { id: "intro" }
                    },
                    [
                      _c("h5", [_vm._v(_vm._s(_vm.$t("formMedical.titulo")))]),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("formMedical.tratamientosprevios")
                                )
                              )
                            ]),
                            _c("br"),
                            _vm._v(_vm._s(_vm.data.tratamientosprevios) + " ")
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("formMedical.timpoIntentoBebe"))
                              )
                            ]),
                            _c("br"),
                            _vm._v(_vm._s(_vm.data.tiempointentandobebe) + " ")
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("formMedical.tiempodispuestaesperar")
                                )
                              )
                            ]),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.data.tiempodispuestaesperar) + " "
                            )
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("formMedical.altura")))
                            ]),
                            _c("br"),
                            _vm._v(_vm._s(_vm.data.ldb_altura) + " ")
                          ]),
                          _c("b-col"),
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("formMedical.peso")))
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(_vm._s(_vm.data.ldb_peso) + " ")
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("formMedical.primerPeriodo"))
                              )
                            ]),
                            _c("br"),
                            _vm._v(_vm._s(_vm.data.primerperiodo) + " ")
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("formMedical.diasPeriodo")))
                            ]),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.data.duracionperiodo) + " ")
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("formMedical.dolorPeriodo")))
                            ]),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.data.dolorperiodo) + " ")
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "cont-div-fm ml-1 cont-div-fm-check"
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("formMedical.padecimientos"))
                                )
                              ]),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "diabetes",
                                          _vm.data.padecimientos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.diabetes"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "cardiacos",
                                          _vm.data.padecimientos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.cardiacos"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "hipertension",
                                          _vm.data.padecimientos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.hipertension"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "cancer",
                                          _vm.data.padecimientos
                                        )
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("formMedical.cancer")))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "VIH",
                                          _vm.data.padecimientos
                                        )
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("formMedical.vih")))
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "hepatitis",
                                          _vm.data.padecimientos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.hepatitis"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "otros",
                                          _vm.data.padecimientos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.hepatitis"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "ninguno",
                                          _vm.data.padecimientos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.ninguno"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "cont-div-fm ml-1  cont-div-fm-check"
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "formMedical.padecimientosFamiliares"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "diabetes",
                                          _vm.data.padecimientos_familia
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.diabetes"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "cardiacos",
                                          _vm.data.padecimientos_familia
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.cardiacos"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "hipertension",
                                          _vm.data.padecimientos_familia
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.hipertension"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "cancer",
                                          _vm.data.padecimientos_familia
                                        )
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("formMedical.cancer")))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "VIH",
                                          _vm.data.padecimientos_familia
                                        )
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("formMedical.vih")))
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "hepatitis",
                                          _vm.data.padecimientos_familia
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.hepatitis"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "otros",
                                          _vm.data.padecimientos_familia
                                        )
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("formMedical.otros")))
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "ninguno",
                                          _vm.data.padecimientos_familia
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.ninguno"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "cont-div-fm ml-1 cont-div-fm-check"
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "formMedical.padecimientosInfertilidad"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "endometriosis",
                                          _vm.data.padecimientos_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("formMedical.endometriosis")
                                      )
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "miomatosis",
                                          _vm.data.padecimientos_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.miomatosis"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "quistes",
                                          _vm.data.padecimientos_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.quistes"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "agnesia_uterina",
                                          _vm.data.padecimientos_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("formMedical.agnesia_uterina")
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "ovario_poliquistico",
                                          _vm.data.padecimientos_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "formMedical.ovario_poliquistico"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "otros",
                                          _vm.data.padecimientos_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("formMedical.otros")))
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "ninguno",
                                          _vm.data.padecimientos_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.ninguno"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "cont-div-fm ml-1 cont-div-fm-check"
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("formMedical.estudiosInfertilidad")
                                  )
                                )
                              ]),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "ultrasonido",
                                          _vm.data.estudios_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.ultrasonido"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "transferencia_embionaria",
                                          _vm.data.estudios_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "formMedical.transferencia_embionaria"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "hormonales",
                                          _vm.data.estudios_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.hormonales"))
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "seminograma",
                                          _vm.data.estudios_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.seminograma"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "",
                                          _vm.data.estudios_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("formMedical.otros")))
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "ninguno",
                                          _vm.data.estudios_fertilidad
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.ninguno"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("formMedical.embarazada")))
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.data.embarazoprevio))])
                          ]),
                          _c("b-col"),
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("formMedical.partos")))
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.data.partosprevios))])
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "cont-div-fm ml-1 cont-div-fm-check"
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("formMedical.procedimientos"))
                                )
                              ]),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "dialisis",
                                          _vm.data.procedimientos_previos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.dialisis"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "transplantes",
                                          _vm.data.procedimientos_previos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.transplantes"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "legrado",
                                          _vm.data.procedimientos_previos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.legrado"))
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "cesarea",
                                          _vm.data.procedimientos_previos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.cesarea"))
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "",
                                          _vm.data.procedimientos_previos
                                        )
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("formMedical.otros")))
                                  ]),
                                  _c("b-col", [
                                    _c("span", {
                                      class: {
                                        active: _vm.validateCheck(
                                          "ninguno",
                                          _vm.data.procedimientos_previos
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("formMedical.ninguno"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("formMedical.alergias")))
                            ]),
                            _c("br"),
                            _vm._v(_vm._s(_vm.data.ldb_alergias) + " ")
                          ]),
                          _c("b-col"),
                          _c("b-col", { staticClass: "cont-div-fm ml-1" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("formMedical.medicamentos")))
                            ]),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.data.ldb_medicamentosactuales) + " "
                            )
                          ]),
                          _c("b-col")
                        ],
                        1
                      ),
                      _c("br"),
                      _c("br"),
                      _c("br")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }